<template>
  <div id="divcontent">
    <div id="divleft">
      <ckeditor ref="contentDiv" :editor="editor" id="editor" v-model="editorData" :config="editorConfig">
      </ckeditor>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "ckeditor5ls"
export default {

  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        language: "zh-cn",
      },
      uuid: "",
    };
  },
  async mounted() {
    this.uuid = null
    this.addIframeEventListener()
    this.sendIframeWinpMessage("")
    setInterval(() => {
      this.sendData()
    }, 600)
  },

  methods: {
    //发送消息
    sendIframeWinpMessage(data) {
      window.parent.postMessage({
        params: data,
        uuid: this.uuid,
      }, "*");
    },
    addIframeEventListener() {
      var that = this
      //监听
      window.addEventListener("message", function (event) {
        var data = event.data;
        if (!data) {
          return
        }
        if (!data.uuid) {
          return
        }
        // 定义一个变量去接收,然后就可以获得vue传过来的数据
        var test = data.params;
        if (test || data.uuid) {
          that.uuid = data.uuid
          that.editorData = test
        }
      }, '*')
    },
    sendData() {
      if (this.uuid) {
        let data = ""
        try {
          const blackTriangles = document.querySelectorAll('.ck.ck-reset_all.ck-widget__type-around');
          blackTriangles.forEach(item => { item.remove() });
          document.querySelectorAll('p').forEach(item => {
            if (item.innerHTML.indexOf("图片组件") != -1) {
              item.remove()
            }
          })
          let dataDiv = document.querySelectorAll('[aria-label="编辑器编辑区域：main"]')[0]
          if (dataDiv) {
            data = dataDiv.innerHTML
          }
        }
        catch (error) {
          console.log("出错了")
        }
        this.sendIframeWinpMessage(data)
      }
    }
  }

};
</script>

<style lang="scss" scoped>
#divcontent {
  display: flex;
  overflow: auto;
}

#divleft {
  width: 100%;
  overflow: auto;
}

::v-deep .ck-editor__editable_inline {
  // min-height: 500px !important;
  height: 550px !important;
}
</style>

